import PropTypes from 'prop-types';
import SearchAndSortableTable from '../../../../../../../components/table/SearchAndSortableTable';
import { STUDENT_FILTER, useGetSelectedStudentTable, useHandleActiveStudentFilter } from './StudentTableSelectorHooks';
import './StudentTableSelector.scss';
import DropdownInput from '../../../../../../../components/selector/DropdownInput';

const StudentTableSelector = ({
  onDataChange,
  selectedStudentIds,
  teacherStudentSelectorTableData,
}) => {
  const {
    studentFilterValue,
    handleStudentFilterChange,
  } = useHandleActiveStudentFilter();
  const {
    tableConfig,
    tableData,
  } = useGetSelectedStudentTable({
    selectedStudentIds,
    onDataChange,
    teacherStudentSelectorTableData,
    activeStudent: studentFilterValue,
  });

  return (
    <div className='student-table-selector'>
      <SearchAndSortableTable
        containerClassName='student-selector-table'
        config={tableConfig}
        tableData={tableData}
        searchPlaceholder='Search your students'
        initTableState={{
          order: 'desc',
          orderBy: 'isSelected',
        }}
        toolbarActions={[
          <DropdownInput
            dropdownValues={[{
              id: STUDENT_FILTER.ALL,
              name: 'All Student',
            },
            {
              id: STUDENT_FILTER.ACTIVE,
              name: 'Active',
            },
            {
              id: STUDENT_FILTER.INACTIVE,
              name: 'Inactive',
            },
            ]}
            name='sort'
            value={studentFilterValue}
            onChange={handleStudentFilterChange}
            data-test='sort-by-active-student'
            containerClass='studetn-filter-dropdown'
          />,
        ]}
        searchFunction={(keyword, data) => {
          const name = `${data.firstName} ${data.lastName}`;
          const isNameMatched = name.toLowerCase().indexOf(keyword.toLowerCase()) >= 0;
          const isEmailMatched = data.username.toLowerCase().indexOf(keyword.toLowerCase()) >= 0;
          return isNameMatched || isEmailMatched;
        }}
        autoResetTableState={false}
      />
    </div>
  );
};

StudentTableSelector.defaultProps = {
  onDataChange: () => null,
  teacherStudentSelectorTableData: {},
  selectedStudentIds: [],
};

StudentTableSelector.propTypes = {
  teacherStudentSelectorTableData: PropTypes.array,
  onDataChange: PropTypes.func,
  selectedStudentIds: PropTypes.array,
};

export default StudentTableSelector;
